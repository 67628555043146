<script>
</script>

<aside class="highlight" style="background-image:url(/amenityboss.jpg)">
  <img src="/amenityboss.svg" alt="Amenity Boss" height="32" />
  <h1>Control your over-crowded pools with NFC Key Tags.</h1>
  <ul>
    <li>Scan or tap with smartphone to validate</li>
    <li>NFC technology for access control</li>
    <li>Use for fitness centers and other spaces</li>
  </ul>
  <a
    target="_blank"
    href="https://communityboss.com/amenityboss?utm_campaign=amenityboss&utm_source=login"
    >learn more</a
  >
  <img
    src="/amenityboss@2x.png"
    alt="Phone Image"
    width="465"
    style="margin-left:-32px"
  />
</aside>
