<script>
</script>

<aside class="highlight" style="background-image:url(/parkingboss.jpg)">
  <img src="/parkingboss.svg" alt="Parking Boss" height="32" />
  <h1>Generate new income from your guest parking.</h1>
  <ul>
    <li>Add to your existing parking attendant</li>
    <li>Offer free time before charging</li>
    <li>No setup fees</li>
  </ul>
  <a
    target="_blank"
    href="https://communityboss.com/parkingboss?utm_campaign=parkingboss&utm_source=login"
    >learn more</a
  >
  <img
    src="/parkingboss@2x.png"
    alt="Phone Image"
    width="492"
    style="margin-left:-16px"
  />
</aside>
