<script>
    import { onMount, tick } from "svelte";
    import { query } from "../util/router";

    export let email = null;
    export let password = null;
    export let path = null;
    export let param = "login_hint";

    let submittable = false;
    let submitting = false;

    let success = null;
    export let error = null;
    let form = null;

    function checkSubmittable(form) {
        return submittable = !!(form && form.checkValidity && form.checkValidity());
    }

    function change(e) {
        //console.log("checking change");
        return submittable = checkSubmittable(e.target.form || e.target);
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting) return;

        const form = e.target;

        if(!checkSubmittable(form)) return;

        submitting = true;
        error = success = null;

        //email = form.elements["email"].value;

        if(form.elements["password"]) password = form.elements["password"].value;

        query(param, encodeURIComponent(form.elements["email"].value), path);

        
        //form.reset();
    }

    $: if(error) submitting = false;

    // run when form attached
    // $: if(form) {
    //     checkSubmittable(form);
    //     form.blur();
    // }

    // async function animation(e) {
    //     //await tick();
    //     console.log("animation=",e);
    //     blur();
    //     setTimeout(() => change(e), 0);
    // }

    onMount(async () => {
        await tick();
        submittable = checkSubmittable(form);
    });
</script>
<form bind:this={form} on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
        <ul>
            <li class:error={!!error}>
                <!-- <label for="login-email">Email</label> -->
                <input id="login-email" type="email" name="email" spellcheck="false" required="required"  value="{email || ""}" placeholder="Enter your login email" autocomplete="username">
            </li>
            <li style="display:none">
                <input type="password" name="password" spellcheck="false">
            </li>
        </ul>
    </fieldset>
    {#if success}
    <p class="success">{success}</p>
    {/if}
    {#if error}
    <p class="error">{error}</p>
    {/if}
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Validating…": "Continue" }</button>
        
    </footer>

</form>