<script>
  import {
    client_id,
    redirect_uri,
    view,
    username,
    user,
    idtoken,
    format,
  } from "./util/stores";
  import { smscreen } from "./util/envstores";
  import EmailLogin from "./components/EmailLogin.svelte";
  import EmailResolve from "./components/EmailStep.svelte";
  import PasswordLogin from "./components/PasswordLogin.svelte";
  import Promo from "./components/Promo.svelte";
  import { fetchExchangeIDToken } from "./api";
  import { isUrlApproved } from "./util/grantaccess";
  import { route } from "./util/router";

  let token = null;
  let email = null;
  let password = null;

  $: if ($username) email = $username;

  $: if ($user && $user.authority) {
    window.location.href = `${$user.authority}?redirect_uri=${
      window.location.origin
    }/login&response_type=id_token&scope=openid email&client_id=${
      $user.client_id
    }&login_hint=${$user.username}&hd=${$user.domain || "*"}`;
  }

  $: if ($idtoken) {
    // have an id token, exchange for a real one
    handleIDToken($idtoken);
  }

  async function handleIDToken(idtoken) {
    const json = await fetchExchangeIDToken(idtoken);
    if (json.token) {
      token = json.token;
      //alert(token);
    }
  }

  // assume that query formats came in with properly handled decoding
  $: if (token && $redirect_uri && isUrlApproved($redirect_uri)) {
    if ($format.toLowerCase() === "query") {
      const url = new URL($redirect_uri);
      url.searchParams.set("access_token", token);
      window.location.href = url.toString();
    } else {
      window.location.href =
        decodeURIComponent($redirect_uri).split("#")[0] +
        "#access_token=" +
        token;
      //redirect_uri.set(null);
    }
  }
</script>

<header>
  <h1>Manage like&nbsp;a&nbsp;boss.</h1>
</header>
<main>
  <!-- <figure class="validating">Validating…</figure>   -->
  {#if token && $redirect_uri}
    <figure class="validating">Logging in…</figure>
  {:else if "email" == $view}
    <EmailLogin bind:email />
    <!-- <p>Good to go? <a href="/login?login_hint={escape(encodeURIComponent(email||""))}">Return to login…</a></p> -->
  {:else if $username && $user && $user.authority}
    <figure class="validating">Redirecting to login…</figure>
  {:else if $username && !$user}
    <figure class="validating">Checking {$username}…</figure>
  {:else if $idtoken}
    <figure class="validating">Logging in…</figure>
  {:else if $user && $user.provider && $user.username}
    <!-- <header>
        <h1>{$user.username}</h1>
        <p><a href="/login">change…</a></p>
    </header> -->
    <PasswordLogin {password} email={$user.username} bind:token />
    <!-- <p>Forgot your password? <a href="/login/reset?login_hint={escape(encodeURIComponent($user.username))}">Request a reset link…</a></p> -->
  {:else}
    <EmailResolve
      path="/login/password"
      bind:password
      email={$username}
      error={$user && !$user.username && "Sorry, we don't recognize this email"}
    />
  {/if}
  {#if !$smscreen}
    <Promo />
  {/if}
</main>
<nav>
  <ul>
    <li>
      Questions? Visit our <a href="https://help.communityboss.app/"
        >help center</a
      >
    </li>
    <li>
      Our <a
        href="https://help.communityboss.app/en/articles/3112854-terms-conditions"
        >terms of use</a
      >
      &amp;
      <a
        href="https://help.communityboss.app/en/articles/1833164-privacy-policy"
        >privacy policy</a
      >
    </li>
  </ul>
</nav>
<footer>
  <p>Copyright &copy; Luminous. All rights reserved.</p>
</footer>
