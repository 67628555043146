import { Api } from '@parkingboss/api';

export const api = new Api({
    client: "auth-web",
    skipUrlRewrite:true,
});

export async function fetchExchangeIDToken(idtoken) {

    var data = new FormData();
    data.append("id_token", idtoken);

    const res = await fetch(`${api.settings.apiBase}/auth/tokens?viewpoint=${new Date().toISOString()}&id_token=${idtoken}&lifetime=P7D`, {
        method:"POST",
        body:data
    });
    const json = await res.json();

    return json;

}

export async function fetchAuth(username) {


    const res = await fetch(`${api.settings.apiBase}/auth?viewpoint=${new Date().toISOString()}&username=${encodeURIComponent(username)}`);
    const json = await res.json();
    return json;

}

export async function fetchEmailPasswordToken(email, password) {

    var data = new FormData();
    data.append("email", email);
    data.append("password", password);


    const res = await fetch(`${api.settings.apiBase}/auth/tokens?viewpoint=${new Date().toISOString()}&email=${encodeURIComponent(email)}&lifetime=P7D`, {
        method:"POST",
        body:data
    });
    const json = await res.json();

    return json;

}

export async function fetchSendEmailToken(email) {
    var data = new FormData();
    data.append("email", email);


    const res = await fetch(`${api.settings.apiBase}/auth/tokens/email?viewpoint=${new Date().toISOString()}&email=${encodeURIComponent(email)}`, {
        method:"POST",
        body:data
    });
    const json = await res.json();

    return json;
}