import { derived, writable } from "svelte/store";

export const params = writable({});

function paramStore(
  selector,
  onlyUpdateForRealValues = false,
  defaultValue = null
) {
  var value = null;
  return derived(params, (params, set) => {
    if (!params) return;
    var newValue = selector(params);
    if (!newValue && null != defaultValue) newValue = defaultValue;
    if (!newValue && !value) return;
    if (!newValue && onlyUpdateForRealValues) return;
    //console.log("setting new paramstore value", newValue);
    if (newValue != value) set((value = newValue));
  });
}

export const get = (property, onlyUpdateWhenSet = false, defaultValue = null) =>
  paramStore(($params) => $params[property], onlyUpdateWhenSet, defaultValue);

export function set(key, value) {
  params.update(($value) =>
    Object.assign($value, {
      [key]: value,
    })
  );
}
